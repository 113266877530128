<template>
  <div class="ln-reviews">
    <header class="ln-head ln-head--sm">
      <div class="ln-head__titulo ln-head__titulo--h3">{{ $t("landings.reviews.title", lang) }}</div>
    </header>

    <div class="ln-reviews__items">
      <VueSlickCarousel v-bind="reviewsSection">
        <div class="ln-reviews__item">
          <div class="b-card">
            <div class="testimonial-item">
              <div class="testimonial-item__text">
                "{{ $t("landings.reviews.first", lang) }}"
              </div>
              <div class="testimonial-item__name">{{ $t("landings.reviews.firstname", lang) }}</div>
            </div>
          </div>
        </div>
        <div class="ln-reviews__item">
          <div class="b-card">
            <div class="testimonial-item">
              <div class="testimonial-item__text">
                "{{ $t("landings.reviews.second", lang) }}"
              </div>
              <div class="testimonial-item__name">{{ $t("landings.reviews.secondname", lang) }}</div>
            </div>
          </div>
        </div>
        <div class="ln-reviews__item">
          <div class="b-card">
            <div class="testimonial-item">
              <div class="testimonial-item__text">
                "{{ $t("landings.reviews.third", lang) }}"
              </div>
              <div class="testimonial-item__name">{{ $t("landings.reviews.thirdname", lang) }}</div>
            </div>
          </div>
        </div>
      </VueSlickCarousel>
    </div>
  </div>
</template>

<script>
import VueSlickCarousel from "vue-slick-carousel";
import "vue-slick-carousel/dist/vue-slick-carousel.css";
import "vue-slick-carousel/dist/vue-slick-carousel-theme.css";

export default {
  name: "LandingReviews",
  components: {
    VueSlickCarousel,
  },
  props: {
    lang: {
      type: String,
      required: false,
      default: "es-ES",
    },
  },
  data() {
    return {
      reviewsSection: {
        slidesToShow: 4,
        arrows: false,
        responsive: [
          {
            breakpoint: 9999,
            settings: "unslick",
          },
          {
            breakpoint: 991,
            settings: {
              slidesToShow: 2,
              draggable: true,
              centerPadding: "50px",
              dots: true,
            },
          },
          {
            breakpoint: 767,
            settings: {
              slidesToShow: 1,
              draggable: true,
              dots: true,
            },
          },
        ],
      },
    };
  },
};
</script>

<style lang="scss" scoped></style>
