<template>
  <footer class="ln-footer">
    <div class="bb-container">
      <div class="ln-footer__container">
        <div class="ln-footer__copy">© helloBB 2024</div>
        <div class="ln-footer__nav">
          <router-link to="/cookies">{{ $t("cookiesPolicy.cookiesPolicy", lang) }}</router-link>
          <router-link :to="$t('routes.confidentialityPolicy', lang)">{{ $t("privacyPolicy.privacyPolicy", lang) }}</router-link>
          <router-link :to="$t('routes.legalNotice', lang)">{{ $t("legalNotice.legalNotice", lang) }}</router-link>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: "LandingFooter",
  props: {
    lang: {
      type: String,
      required: false,
      default: "es-ES",
    },
  },
};
</script>
